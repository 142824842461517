<template>
	<div>
		<AppointmentForm class="customer-create-appt" @submit-form="createNewAppt"></AppointmentForm>
	</div>
</template>

<script>
import { CREATE_APPOINTMENT } from "./../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("serviceAppointment")

export default {
	name: "CustomerCreateAppt",

	components: {
		AppointmentForm: () => import("./../components/AppointmentForm.vue"),
	},

	props: {},

	data() {
		return {}
	},

	computed: {},

	watch: {},

	created() {
		// this.postToIframe()
	},

	methods: {
		...mapActions({ CREATE_APPOINTMENT }),

		createNewAppt(dataParams) {
			this.CREATE_APPOINTMENT(dataParams)
		},

		postToIframe() {
			window.parent.postMessage("service-appt", "*")
		},
	},
}
</script>

<style lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";
.customer-create-appt {
	background-color: $color-white;
	.appt-form {
		@include form;
		@include font-lato;

		&__heading {
			font-size: 16px;
			font-weight: 400;
			color: $color-black;

			h1 {
				font-size: 32px;
				color: $color-bahama-blue;
			}

			a {
				color: $color-pink3;
				font-weight: bold;
				font-size: 16px;
			}

			p {
				color: $color-siam;
				max-width: 670px;
			}

			.quote {
				font-weight: 700;
				color: $color-pink4;
			}
		}
	}

	.btn-finish {
		padding: 14px 32px;
		font-weight: bold;
		background-color: $color-blue;
		border-color: $color-blue;
	}

	.mx-input {
		color: $color-pink1;
	}

	.form-group {
		&__label {
			color: $color-blue1 !important;
		}
	}
}
</style>
